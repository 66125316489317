const countriesName = {
    vi:"Vietnam",
    fr:"France",
    ci:"Côte d'Ivoire",
    bj:"Bénin",
    cn:"Chine",
    africa:"Multiples pays d'Afrique - Transrégional"
}


export default countriesName;